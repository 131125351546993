import { extendTheme } from "@chakra-ui/react";


const theme = {
  breakpoints: {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    '2xl': '1536px',
  },
  colors: {
    primary: {
      500: "#082471FF",
      900: "#082471FF"
    },
    background: {
      azureishwhite: "#DBE9F8",
    },
    border: {
      gainsboro: "#DEDEDE",
    },
    text: {
      primary: "#252C52",
    },
    white: "#FFFFFF",
  },
};


export default extendTheme(theme);
